import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

const FinishedProductForm = ({ handleBackClick }) => {
  const [name, setName] = useState('');
  const [dateProduced, setDateProduced] = useState('');
  const [itemNumber, setItemNumber] = useState(''); // New item number state
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Reset error state
    setErrors({});

    // Validate form fields
    const newErrors = {};
    const today = new Date().toISOString().split('T')[0];
    if (!name) newErrors.name = 'Product name is required.';
    if (!dateProduced) {
      newErrors.dateProduced = 'Date produced is required.';
    } else if (dateProduced < today) {
      newErrors.dateProduced = 'Date produced cannot be in the past.';
    }
    if (selectedMaterials.length === 0) newErrors.selectedMaterials = 'At least one raw material or finished product is required.';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const rawMaterialIds = selectedMaterials.map(material => material.id);
      
    const finishedProductData = {
      name,
      dateProduced,
      itemNumber // Include item number in the data
    };

    const dto = {
      finishedProduct: finishedProductData,
      rawMaterialIds
    };

    try {
      const response = await fetch('https://trackingnumbers.azurewebsites.net/api/finishedproducts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dto),
      });

      const responseData = await response.json();
      if (response.ok) {
        handleBackClick();
        navigate('/');
      } else {
        setErrors({ submit: responseData.message || 'Error submitting finished product.' });
      }
    } catch (error) {
      setErrors({ submit: 'Error submitting finished product.' });
    }
  };

  const handleBack = () => {
    handleBackClick();
    navigate('/');
  };

  const loadOptions = debounce((inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      callback([]);
      return;
    }

    const fetchOptions = async () => {
      try {
        const [rawMaterialResponse, finishedProductResponse] = await Promise.all([
          fetch(`https://trackingnumbers.azurewebsites.net/api/rawmaterials/search?query=${inputValue}`, { credentials: 'include' }),
          fetch(`https://trackingnumbers.azurewebsites.net/api/finishedproducts/search?query=${inputValue}`, { credentials: 'include' })
        ]);

        const [rawMaterialData, finishedProductData] = await Promise.all([
          rawMaterialResponse.json(),
          finishedProductResponse.json()
        ]);

        const rawMaterialsArray = (rawMaterialData.$values || []).filter(material => new Date(material.dateReceived) >= new Date());
        const finishedProductsArray = (finishedProductData.$values || []).filter(product => new Date(product.dateProduced) >= new Date());

        const options = [...rawMaterialsArray, ...finishedProductsArray]
          .sort((a, b) => new Date(b.dateReceived || b.dateProduced) - new Date(a.dateReceived || a.dateProduced))
          .map(material => ({
            id: material.id,
            value: material.batchNumber,
            label: `${material.name} (${material.batchNumber})`,
            batchNumber: material.batchNumber
          }));

        callback(options);
      } catch (error) {
        callback([]);
      }
    };

    fetchOptions();
  }, 300);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <FaArrowLeft
            size={24}
            onClick={handleBack}
            style={{ cursor: 'pointer' }}
          />
          <Card.Title>Færdigt produkt</Card.Title>
          <div style={{ width: '24px' }}></div> {/* Placeholder for alignment */}
        </div>
        {errors.submit && <Alert variant="danger">{errors.submit}</Alert>}
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Group controlId="name">
            <Form.Label>Produkt navn</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={!!errors.name}
            />
            {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group controlId="dateProduced" className="mt-3">
            <Form.Label>Udløbsdato</Form.Label>
            <Form.Control
              type="date"
              value={dateProduced}
              min={today} // Restrict to today and onwards
              onChange={(e) => setDateProduced(e.target.value)}
              isInvalid={!!errors.dateProduced}
            />
            {errors.dateProduced && <Form.Control.Feedback type="invalid">{errors.dateProduced}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group controlId="itemNumber" className="mt-3">
            <Form.Label>Varenummer (ikke påkrævet)</Form.Label>
            <Form.Control
              type="text"
              value={itemNumber}
              onChange={(e) => setItemNumber(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="rawMaterials" className="mt-3">
            <Form.Label>Materialer brugt:</Form.Label>
            <AsyncSelect
              isMulti
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              value={selectedMaterials}
              onChange={setSelectedMaterials}
              className={`mt-2 ${errors.selectedMaterials ? 'is-invalid' : ''}`}
              placeholder="Indtast navn eller batch nummer"
            />
            {errors.selectedMaterials && <div className="invalid-feedback d-block">{errors.selectedMaterials}</div>}
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Opret vare
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FinishedProductForm;
