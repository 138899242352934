import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import RawMaterialForm from './forms/RawMaterialForm';
import FinishedProductForm from './forms/FinishedProductForm';
import SearchPage from './pages/SearchPage';
import Login from './pages/LoginPage';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {
      showButtons: true,
      isAuthenticated: false,
      isLoading: true
    };
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth = async () => {
    try {
      const response = await fetch('https://trackingnumbers.azurewebsites.net/api/auth/check', {
        method: 'GET',
        credentials: 'include'
      });

      if (response.ok) {
        this.setState({ isAuthenticated: true, isLoading: false });
      } else {
        this.setState({ isAuthenticated: false, isLoading: false });
      }
    } catch (error) {
      this.setState({ isAuthenticated: false, isLoading: false });
    }
  };

  handleButtonClick = () => {
    this.setState({ showButtons: false });
  };

  handleBackClick = () => {
    this.setState({ showButtons: true });
  };

  handleLogin = async () => {
    this.setState({ isAuthenticated: true });
    // Adding a delay to ensure the token is set in cookies
    await new Promise(resolve => setTimeout(resolve, 500));
    //window.location.reload(); // Reload the page to recheck authentication and refresh state
  };

  handleLogout = async () => {
    try {
      await fetch('https://trackingnumbers.azurewebsites.net/api/auth/logout', {
        method: 'POST',
        credentials: 'include'
      });
      this.setState({ isAuthenticated: false, showButtons: true });
    } catch (error) {
    }
  };

  render() {
    const { isAuthenticated, isLoading, showButtons } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
        <Container fluid>
          <Row className="justify-content-md-center my-4">
            <Col xs={12} className="text-center">
              {isAuthenticated ? (
                <>
                  {showButtons ? (
                    <>
                      <Button variant="primary" as={Link} to="/raw-material" className="m-2" onClick={this.handleButtonClick} id="button">
                        Tilføj rå-vare
                      </Button>
                      <Button variant="primary" as={Link} to="/finished-product" className="m-2" onClick={this.handleButtonClick} id="button">
                        Tilføj produkt
                      </Button>
                      <Button variant="primary" as={Link} to="/search" className="m-2" onClick={this.handleButtonClick} id="button">
                        Søg database
                      </Button>
                      <Button variant="danger" className="m-2" onClick={this.handleLogout} id="button">
                        Logout
                      </Button>
                    </>
                  ) : (
                    <Routes>
                      <Route
                        path="/raw-material"
                        element={<RawMaterialForm handleBackClick={this.handleBackClick} />}
                      />
                      <Route
                        path="/finished-product"
                        element={<FinishedProductForm handleBackClick={this.handleBackClick} />}
                      />
                      <Route
                        path="/search"
                        element={<SearchPage handleBackClick={this.handleBackClick} />}
                      />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  )}
                </>
              ) : (
                <Routes>
                  <Route path="/login" element={<Login handleLogin={this.handleLogin} />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              )}
            </Col>
          </Row>
        </Container>
    );
  }
}
