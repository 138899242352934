import React, { useState } from 'react';
import { Form, Card, Table } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

const SearchPage = ({ handleBackClick }) => {
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [results, setResults] = useState([]);

  const loadOptions = debounce((inputValue, callback) => {
    if (!inputValue || inputValue.length < 3) {
      callback([]);
      return;
    }

    const fetchOptions = async () => {
      try {
        const [rawMaterialResponse, finishedProductResponse] = await Promise.all([
          fetch(`https://trackingnumbers.azurewebsites.net/api/rawmaterials/search?query=${inputValue}`, { credentials: 'include' }),
          fetch(`https://trackingnumbers.azurewebsites.net/api/finishedproducts/search?query=${inputValue}`, { credentials: 'include' })
        ]);

        const [rawMaterialData, finishedProductData] = await Promise.all([
          rawMaterialResponse.json(),
          finishedProductResponse.json()
        ]);

        const rawMaterialsArray = rawMaterialData.$values || [];
        const finishedProductsArray = finishedProductData.$values || [];

        const options = [...rawMaterialsArray, ...finishedProductsArray]
          .sort((a, b) => new Date(b.dateReceived || b.dateProduced) - new Date(a.dateReceived || a.dateProduced))
          .map(material => ({
            value: material.id,
            label: `${material.name} (${material.batchNumber})`,
            batchNumber: material.batchNumber,
            name: material.name,
            id: material.id,
            dateReceived: material.dateReceived,
            itemNumber: material.itemNumber,
            type: rawMaterialsArray.includes(material) ? 'RawMaterial' : 'FinishedProduct'
          }));

        callback(options);
      } catch (error) {
        console.error('Error fetching options:', error);
        callback([]);
      }
    };

    fetchOptions();
  }, 300);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSearch = async (selectedOption) => {
    setSelectedMaterial(selectedOption);
    
    try {
      const response = await fetch(`https://trackingnumbers.azurewebsites.net/api/finishedproducts/getLinkings/${selectedOption.id}`, { credentials: 'include' });
      const data = await response.json();

      

      const linkedMaterials = data.linkedRawMaterials?.$values || [];
      const linkedProducts = data.linkedProducts?.$values || [];

      const results = [
        ...linkedMaterials.map(material => ({
          id: material.id,
          name: material.name,
          batchNumber: material.batchNumber,
          dateProduced: material.dateProduced,
          itemNumber: material.itemNumber,
          type: 'RawMaterial'
        })),
        ...linkedProducts.map(product => ({
          id: product.id,
          name: product.name,
          batchNumber: product.batchNumber,
          dateReceived: product.dateReceived,
          itemNumber: product.itemNumber,
          type: 'FinishedProduct'
        }))
      ];


      setResults(results);
    } catch (error) {
      console.error('Error fetching linked data:', error);
    }
  };

  const handleBack = () => {
    handleBackClick();
  };

  const renderResults = () => {
    if (results.length === 0) {
      return null;
    }

    const today = new Date().toISOString().split('T')[0];

    return (
      <div className="mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Navn</th>
              <th>Batch nummer</th>
              <th>Varenummer</th>
              <th>Udløbsdato</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => {
              const isExpired = new Date(result.dateProduced || result.dateReceived) < new Date(today);
              return (
                <tr key={index} >
                  <td>{result.type === 'FinishedProduct' ? 'Product' : 'Raw Material'}</td>
                  <td>{result.name}</td>
                  <td>{result.batchNumber}</td>
                  <td>{result.itemNumber}</td>
                  <td style={{ color: isExpired ? 'red' : 'green' }}>{formatDate(result.dateReceived)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <FaArrowLeft
            size={24}
            onClick={handleBack}
            style={{ cursor: 'pointer' }}
          />
          <Card.Title>Søg i databasen</Card.Title>
          <div style={{ width: '24px' }}></div> {/* Placeholder for alignment */}
        </div>
        <Form>
          <Form.Group controlId="query">
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              value={selectedMaterial}
              onChange={handleSearch}
              className="mt-2"
              placeholder="Skriv navn eller batch nummer (mindst tre tal/bogstaver)"
            />
          </Form.Group>
        </Form>
        {renderResults()}
      </Card.Body>
    </Card>
  );
};

export default SearchPage;
